body{
    position: relative;
}
section.login{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.box-login{
    width: 35%;
    background-color: #fff;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
    -o-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.5);
    padding: 3em;
    overflow: auto;
}
.box-login h1{
    color: #444444;
    font-size: 1.3em;
    font-weight: 700;
}
.box-login p{
    font-size: 0.9em;
    color: #444444;
}
.fader{
    position: absolute;
    height: 100%;
    width: 65%;
    left:35%;
    top:0;
    z-index: -99;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img-login-logo{
    position: absolute;
    right: 30px;
    top: 30px;
}
@media screen and (max-width: 1200px){
    .box-login {
        width: 40%;
    }
}
@media screen and (max-width: 920px){
    .box-login {
        width: 50%;
    }
}
@media screen and (max-width: 768px){
    section.login {
        position: static;
    }
    .img-login-logo {
        display: none;
    }
    .box-login {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
        max-width: 420px;
        margin-bottom: 10%;
    }
    .fader {
        width: 100%;
        left: 0%;
    }
    body {
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 500px){
    .box-login {
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5%;
        max-width: 100%;
        margin-bottom: 10%;
        padding: 2em;
    }
}