/*Here is only the especifics customizations of classes added by the developer*/
.template-create{
    position: fixed!important;
    width: 100%!important;
    height: 100%!important;
    top: 0!important;
    left: 0!important;
    z-index: 999!important;
    background-color: #fff;
    margin: 0!important;
    padding: 0!important;
}
.form-editor label{
    font-weight: bold;
}

.editor-header {
    padding: 15px 30px;
    width: 100%;
    background: linear-gradient(to right, #1b06da 0%, #a146f6 60%, #a146f6 100%);
}

.editor-header p{
    color: white;
    margin: auto 0px auto 0px;
    font-size: 1em;
    font-weight: bold;
}

@media screen and (max-width: 450px) {

    .editor-header p {
        color: white;
        margin: auto 0px auto 0px;
        font-size: 0.9em;
    }

    .btn-close-editor {
        font-size: 1em !important;
    }

    .editor-body {
        padding: 2% 0% 1% 0%;
    }

    .form-editor{
        flex-direction: column !important;
    }
}

.editor-body{
    padding: 15px 15px;
    overflow-x: scroll;
    width: 100%;
}

.editor-footer {
    border-top: solid 1px #ccc;
    background-color: #fdfdfd;
    padding: 15px 40px;
    width: 100%;
}


.html-selector{
    border-radius: 1rem !important;
}



.btn-editor-footer {
    margin: 1em 1em 1em 1em;
    font-weight: bold;
}

.btn-close-editor {
    color: #fff !important;
    background-color: transparent;
    border-color: #1b06da;
    font-weight: bold !important;
    font-size: 1em ;
    padding: 0.375rem 2rem;
    border-radius: 30px !important;
    border: none;
    box-shadow: none !important;
    border:solid 1px transparent;
}

.btn-close-editor:hover {
    color: #FFF !important;
    background-color: transparent !important;
    border: solid 1px #FFF !important;
}

.btn-close-editor:focus,
.btn-close-editor.focus {
    color: #fff;
    background-color: transparent;
    border-color: #0062cc;
    box-shadow: 0rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}

.btn-close-editor.disabled,
.btn-close-editor:disabled {
    color: #fff !important;
    background-color: transparent;
    border-color: #1b06da;
}

.btn-close-editor:not(:disabled):not(.disabled):active,
.btn-close-editor:not(:disabled):not(.disabled).active,
.show>.btn-close-editor.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: #005cbf;
}

.btn-close-editor:not(:disabled):not(.disabled):active:focus,
.btn-close-editor:not(:disabled):not(.disabled).active:focus,
.show>.btn-close-editor.dropdown-toggle:focus {
    box-shadow: 0rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}



div .container-fluid{
    position: absolute;
    width: 100%;
    height: 100%;
}

.select-languaje-text-editor{
    align-self: end;
}